import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function dateFormat(date?: string | Date) {
  return date ? dayjs.utc(date).format(DATE_FORMAT) : "-";
}

export function dateTimeFormat(date?: string | Date): string | JSX.Element {
  return date ? dayjs.utc(date).format(DATE_TIME_FORMAT) : "-";
}
