import { Table as BaseTable, Button, Center, Collapse, LoadingOverlay, Pagination, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconMinus, IconPlus, IconSelector } from "@tabler/icons-react";
import qs from "qs";
import React, { useEffect, useImperativeHandle, useState } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";
import { dateFormat } from "../../utils/date";

export * from "./row-action";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  filters?: { [key: string]: string | number | null };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  collapse?: boolean;
  collapseChildren?: any;
  isShowDate?: boolean;
};

export type ITableRef = { reload: () => void; firstBalance?: any };

export const AccountStatementTable = React.forwardRef(
  (
    { name, columns, filters = {}, pagination = true, dataSource = [], loadData, collapse = false, collapseChildren, isShowDate = false }: Props,
    ref: React.Ref<ITableRef>,
  ) => {
    const { classes, theme } = useStyles();
    const [opened, setOpened] = React.useState<number[]>([]);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [balance, setBalance] = useState<number>(0);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: 20 });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => {
        let res = loadData && (await loadData({ filter: filters } as any));

        if (res.url) {
          window.location.href = res.url;
        } else return res;
      },
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );
    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
    };

    const onScroll = () => {};

    useEffect(() => {
      setBalance(data?.firstBalance);
    }, [data, data.firstBalance]);

    useImperativeHandle(ref, () => ({
      balance,
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <BaseTable ref={tableRef} horizontalSpacing="md" verticalSpacing="md" highlightOnHover striped="even">
            <BaseTable.Thead>
              <BaseTable.Tr>
                {collapse && <BaseTable.Th className={classes.collapseTh}></BaseTable.Th>}
                {columns.map((column, index) => (
                  <Th key={index} column={column} sorted={sorted[0] === column.dataIndex && sorted[1]} onSort={() => onSort(column.dataIndex)}>
                    {column.title}
                  </Th>
                ))}
              </BaseTable.Tr>
            </BaseTable.Thead>
            <BaseTable.Tbody>
              {data?.rows?.length > 0 ? (
                data?.rows?.map((row: any, index: number) => {
                  return (
                    <>
                      {dateFormat(row.createdAt) !== dateFormat(data?.rows[index - 1]?.createdAt) && isShowDate && (
                        <BaseTable.Tr key={`${index}_collapse`}>
                          <BaseTable.Td colSpan={columns.length + 1}>
                            <Text fw={600} c={theme.primaryColor} px="md" py={"sm"}>
                              {dateFormat(row.createdAt)}
                            </Text>
                          </BaseTable.Td>
                        </BaseTable.Tr>
                      )}
                      <BaseTable.Tr key={`${index}`}>
                        {collapse && (
                          <BaseTable.Td>
                            <Button
                              onClick={() => setOpened(opened.includes(index) ? opened.filter((f) => f !== index) : [...opened, index])}
                              size="xs"
                              w={25}
                              h={25}
                              p={0}
                              variant="default">
                              {opened.includes(index) ? <IconPlus size={18} stroke={1} /> : <IconMinus size={18} stroke={1} />}
                            </Button>
                          </BaseTable.Td>
                        )}
                        {columns.map((column, index2) => {
                          return (
                            <BaseTable.Td key={`${index2}`} className={classes.td} align={column.align || "left"}>
                              {column.render(row, index)}
                            </BaseTable.Td>
                          );
                        })}
                      </BaseTable.Tr>
                      {collapse && (
                        <BaseTable.Tr key={`${index}_collapse`}>
                          <BaseTable.Td colSpan={columns.length + 1} className={classes.tdDetail}>
                            <Collapse in={opened.includes(index)}>
                              <div className={classes.collapseContent}>{collapse && collapseChildren && collapseChildren(row)}</div>
                            </Collapse>
                          </BaseTable.Td>
                        </BaseTable.Tr>
                      )}
                    </>
                  );
                })
              ) : (
                <BaseTable.Tr key={"Noting"}>
                  <BaseTable.Td colSpan={columns.length} className={classes.td}>
                    <Center sx={(theme) => ({ padding: theme.spacing.xl, flexDirection: "column", gap: 10 })}>
                      <IconDatabaseOff size={36} stroke={1} />
                      <Text fw={500} ta="center">
                        Өгөгдөл олдсонгүй.
                      </Text>
                    </Center>
                  </BaseTable.Td>
                </BaseTable.Tr>
              )}
            </BaseTable.Tbody>
          </BaseTable>

          <LoadingOverlay visible={isLoading} title="Түр хүлээнэ үү." />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
            />
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
}

const Th = ({ children, column, sorted, onSort }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <BaseTable.Th className={classes.th} style={{ width: column.width }}>
        {children}
      </BaseTable.Th>
    );

  return (
    <BaseTable.Th className={`${classes.th} ${classes.control}`} style={{ width: column.width }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </BaseTable.Th>
  );
};

const useStyles = createStyles((theme) => ({
  collapseTh: {
    width: "24px",
  },
  collapseContent: {
    borderTop: "1px solid #dadada",
    padding: "14px 24px",
  },
  tdDetail: {
    padding: "0 !important",
    border: "none !important",
  },
  container: {
    position: "relative",
  },
  scroll: {
    overflowX: "auto",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  child: {
    display: "flex",
  },
  icon: {
    display: "flex",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  table: {
    minWidth: 1000,
    position: "relative",
  },
  th: {
    lineHeight: "14px",
    whiteSpace: "nowrap",
    "&:last-child": {
      textAlign: "center",
    },
  },
  td: {},
  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));
