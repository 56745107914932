import { ActionIcon, Avatar, Badge, Flex, LoadingOverlay, Paper, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { IconFileReport, IconPhoto } from "@tabler/icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AccountApi, transactionApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { IBankList } from "../../interfaces/IBankLIst";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { message } from "../../utils/Message";
import { dateFormat } from "../../utils/date";

export const StatementList = () => {
  const breadcrumbs = useBreadcrumb();
  const tableRef = React.useRef<any>(null);
  const [filters] = React.useState({ query: "" });
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = useState<boolean>(false);
  const PAY_STM = usePermission("PAY_STM");

  const navigate = useNavigate();

  const currenciesTag = (val: string) => {
    switch (val) {
      case "TUGRUG": {
        return <Text size="sm">{"Төгрөг"}</Text>;
      }
      case "DOLLAR": {
        return <Text size="sm">{"Доллар"}</Text>;
      }
      default: {
        return <Text size="sm">{val ?? "-"}</Text>;
      }
    }
  };

  const getTransactionData = async (record: any) => {
    setLoading(true);

    try {
      const res: any = await transactionApi.account({
        filter: {
          accountId: record.id,
          inOutType: "ALL",
          endDate: dateFormat(new Date()),
          startDate: dateFormat(new Date()),
        },
      });

      if (res?.hasUrl === true && res?.url) {
        openConfirmModal({
          title: <>Та эхлээд банкны баталгаажуулалтаа хийнэ үү!</>,
          children: <Text size="sm">{""}</Text>,
          labels: {
            confirm: "Нэвтрэх",
            cancel: "Болих",
          },
          onCancel: () => {},
          onConfirm: () => {
            window.open(res.url, "_blank", "width=800,height=600,left=200,top=100");
          },
          centered: true,
        });
      } else {
        navigate(`/statement/${record?.id}`, { state: record });
      }
    } catch (error: any) {
      message.error(error?.message || "Хүсэлт амжилтгүй боллоо.");
    }

    setLoading(false);
  };

  const columns = useHeader({
    currenciesTag,
    bankNames,
    onClick: async (key, record) => {
      switch (key) {
        case "details":
          await getTransactionData(record);
          break;
        default:
          console.log("!");
      }
    },
  });

  return (
    <PageLayout title="Дансны хуулга" subTitle="Хуулга авах дансыг сонгоно уу" breadcrumb={breadcrumbs}>
      {PAY_STM.hasAccess ? (
        <Paper pos={"relative"}>
          <LoadingOverlay visible={loading} title="Түр хүлээнэ үү." />
          <Table ref={tableRef} name="account.list" columns={columns} filters={filters} loadData={(data) => AccountApi.list(data!)} />
        </Paper>
      ) : (
        PAY_STM.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  currenciesTag,
  bankNames,
}: {
  onClick: (key: string, record: IBankList) => void;
  currenciesTag: (key: string) => JSX.Element;
  bankNames: IReference[];
}): ColumnType<IBankList>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      if (record.bankName === "GOLOMT" || record.bankName === "BOGD")
        return (
          <Tooltip position="bottom" label="Хуулга">
            <ActionIcon color="" variant="light" onClick={() => onClick("details", record)}>
              <IconFileReport />
            </ActionIcon>
          </Tooltip>
        );
      else
        return (
          <Tooltip label="Банктай холболт хийгдээгүй!">
            <ActionIcon variant="light" color="gray">
              <IconFileReport />
            </ActionIcon>
          </Tooltip>
        );
    },
  },
  {
    title: "Банкны нэр",
    render: (record) => (
      <Flex gap={10} wrap="nowrap" align="center">
        <Avatar src={record?.icon ?? "-"} radius="xs">
          <IconPhoto />
        </Avatar>
        <Text w={"max-content"} size="sm">
          {bankNames.find((item: IReference) => item.code === record?.bankName)?.name ?? "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => <Text size="sm">{record?.number}</Text>,
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => <Text size="sm">{record?.name}</Text>,
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return currenciesTag(record?.currency);
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record?.isActive === true ? (
        <Badge key={5} color="green" size="md" radius="sm" variant="outline">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge key={6} color="gray" size="md" radius="sm" variant="outline">
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => <Text size="sm">{record.business.regNumber ?? "-"}</Text>,
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => {
      return record?.business ? (
        <div>
          <Text c="indigo" size="sm">
            #{record?.business.partner.refCode}
          </Text>
          <Text size="sm">{record?.business.partner.businessName}</Text>
        </div>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return record?.business ? (
        <div>
          <Text c="indigo" size="sm">
            #{record?.business?.refCode ?? "-"}
          </Text>
          <Text size="sm">{record?.business?.profileName ?? "-"}</Text>
        </div>
      ) : (
        "-"
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "#",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Төлбөр тооцоо",
  },
  {
    label: "Дансны хуулга",
  },
];
