import { Text } from "@mantine/core";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const RecentRevenueTransactionsList = ({ data }: { data: any[] }) => {
  const refTable = useRef<ITableRef>(null);
  const { paymentMethod = [] } = useSelector((state: { general: IGeneral }) => state.general);

  const invitationStatus: any = [];

  const columns = useHeader({ invitationStatus, paymentMethod });

  return (
    <Table
      pagination={false}
      ref={refTable}
      name="recent.transactions.list"
      columns={columns}
      filters={{
        query: "",
        type: "NETWORK",
      }}
      dataSource={data}
    />
  );
};

const useHeader = ({ paymentMethod }: { invitationStatus: any[]; paymentMethod: IReference[] }): ColumnType<any>[] => [
  {
    title: "Огноо, цаг",
    render: (record) => (
      <Text c="dimmed" w="max-content" size="sm">
        {dateTimeFormat(record.createdAt)}
      </Text>
    ),
  },
  {
    title: "Дансны дугаар",
    render: (record) => (
      <Text c="dimmed" w="max-content" size="sm">
        {record.debitAccountNumber}
      </Text>
    ),
  },
  {
    title: "Банкны нэр",
    render: (record) => (
      <Text c="dimmed" w="max-content" size="sm">
        {record.debitAccountName}
      </Text>
    ),
  },
  {
    title: "Орлогын дүн",
    render: (record) => (
      <Text size="sm" style={{ whiteSpace: "nowrap" }} ta="end">
        {currencyFormat(record.totalAmount)}
      </Text>
    ),
  },
  {
    title: "Гүйлгээний төрөл",
    render: (record) => (
      <Text w="max-content" size="sm">
        {paymentMethod.find((item: IReference) => item.code === record.paymentMethod)?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Шилжүүлэгч нэр",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record.creditAccountName}
      </Text>
    ),
  },
];
